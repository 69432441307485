<template>
    <div>
        <b-row class="mt-3">
            <b-col cols="12">
                <h2>Form Assignment</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-form-group
                    id="form-assignment-user-select-label"
                    label="Assign Forms For Whom?"
                    label-for="form-assignment-user-select"
                    class="mb-2"
                >
                    <b-form-select  
                        id="form-assignment-user-select"
                        :options="userSelectList"
                        v-model="selectedUser"
                        :required="true"
                    ></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="selectedUser != null">
            <b-col cols="12">
                <h3>Editing Form Assignments For: {{ allUserRows.filter((usr)=>{ return usr.id == selectedUser; })[0].display_name }}</h3>
            </b-col>
        </b-row>
        <b-row v-if="selectedUser != null">
            <b-col cols="12">
                <b-table
                    small
                    striped
                    :items="allAssignmentRows.filter((row)=>{ return row.user_id == selectedUser; })"
                    :fields="fields"
                >   
                    <template #cell(Form)="data">
                        {{allAvailableForms.filter((frm)=>{ return frm.id == data.item.form_id; })[0].name }}
                    </template>
                    <template #cell(Inspects)="data">
                        {{allAvailableForms.filter((frm)=>{ return frm.id == data.item.form_id; })[0].form_inspects }}
                    </template>
                    <template #cell(Controls)="data">
                        <b-button-group size="sm">
                        <b-button v-if="!data.item.disabled" variant="warning" @click="disableRow(data.item)">Disable</b-button>
                        <b-button v-if="data.item.disabled"  variant="info" @click="enableRow(data.item)">Enable</b-button>
                        <b-button v-if="data.item.is_default" variant="info" @click="notDefaultRow(data.item)">Remove Default</b-button>
                        <b-button v-if="!data.item.is_default" variant="warning" @click="defaultRow(data.item)">Set Default</b-button>
                        </b-button-group>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row v-if="selectedUser != null && allAssignmentRows.filter((row)=>{ return row.user_id == selectedUser; }).length == 0">
            <b-col>
                <h4>This User Does Not Have Any Assigned Forms and Will Use The Default Account Form For All Form Viewing</h4>
            </b-col>
        </b-row>
        <b-row v-if="selectedUser != null">
            <b-col>
                <b-button block size="lg" class="align-middle" variant="success" @click="openAddModal"><span class="align-middle"><i class='material-icons'>add</i></span> <span class="align-middle"> Add Form Assignment</span></b-button>
            </b-col>
        </b-row>
        <b-modal id="add-form" size="md">
            <template v-if="selectedUser != null" slot="modal-title">
                <b-row>
                    <b-col cols="12">
                            Add Form Assignment To {{allUserRows.filter((usr)=>{ return usr.id == selectedUser; })[0].display_name}}
                    </b-col>
                </b-row>
            </template>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        id="form-assignment-modal-form-id-label"
                        label="Form To Add"
                        label-for="form-assignment-modal-form-id"
                        class="mb-2"
                    >
                        <b-form-select  
                            id="form-assignment-modal-form-id"
                            :options="allLoadableFormsSelectList"
                            v-model="modalValues.selectedFormID"
                            :required="true"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-checkbox v-model="modalValues.default" name="check-button" switch required>
                        Set Default
                    </b-form-checkbox>
                </b-col>
            </b-row>

            
            <!-- This Needs To Validate To Ensure That The Alias Isn't Shared Across Multiple Values -->
            <template slot="modal-footer">
                <b-button block v-show="!modalValues.processing" class="mt-3" variant="danger" @click="$bvModal.hide('add-form')">Discard</b-button>
                <b-button block class="mt-3" variant="success" @click="addForm" :disabled="!(modalValues.selectedFormID != null && modalValues.default != null) || modalValues.processing">
                    <b-spinner v-show="modalValues.processing" label="Saving..."></b-spinner>
                    <span v-show="!modalValues.processing">Save</span>
                </b-button>
            </template>
        </b-modal>

        <b-modal id="confirm-collision-fixing" size="md">
            <template v-if="selectedUser != null" slot="modal-title">
                <b-row>
                    <b-col cols="12">
                            Another Form Is Already Default For Inspecting {{warningModal.row.form_inspects}} For {{allUserRows.filter((usr)=>{ return usr.id == selectedUser; })[0].display_name}}
                    </b-col>
                </b-row>
            </template>
            <b-row v-for="(conf, index) in warningModal.collisions" :key="index">
                <b-col cols="12">
                    <b>{{conf.form.name}}</b> Would Be Marked Not Default By This Change
                </b-col>
            </b-row>

            <!-- This Needs To Validate To Ensure That The Alias Isn't Shared Across Multiple Values -->
            <template slot="modal-footer">
                <b-button block v-show="!warningModal.processing" class="mt-3" variant="danger" @click="$bvModal.hide('confirm-collision-fixing')">Nevermind</b-button>
                <b-button block class="mt-3" variant="success" :disabled="warningModal.processing" @click="warningModal.confirmCallback">
                    <b-spinner v-show="warningModal.processing" label="Saving..."></b-spinner>
                    <span v-show="!warningModal.processing">Accept Changes</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = require('underscore');
const uuidv4 = require('uuid/v4');

export default {
    name: 'formassignment',
    components:{

    },
    props:{

    },
    data(){
        return{
            // Users
            allUserRows: [],
            userSelectList: [],

            // Selected User
            selectedUser: null,

            // Assignment List
            allAssignmentRows: [],
            
            // table fields
            fields: ['Form', 'Inspects', 'disabled', 'is_default', 'Controls' ],

            // Available Forms
            allAvailableForms: [],
            allLoadableFormsSelectList: [],
            formOptionsByType: {},
            
            // Add Form Modal
            modalValues:{
                processing: false,
                selectedFormID: null,
                default: false,
            },
            warningModal:{
                processing: false,
                row: {},
                collisions: [],
                confirmCallback: null
            }
        }
    },
    methods:{
        openAddModal(){
            this.$bvModal.show('add-form');
            this.modalValues.processing = false;
            this.modalValues.selectedFormID = null;
            this.modalValues.default = false;
            this.modalValues.checked = false;
        },
        addForm(){
            this.modalValues.processing = true;
            var match = this.allAvailableForms.filter((avf)=>{ return avf.id == this.modalValues.selectedFormID; })[0];
            // console.log(match);
            if(this.modalValues.default){
                if(!this.checkForCollisionsOnRow(match, this.unDefaultCollisionsAndSave)){
                    this.saveNewRow();
                }
            }else{
                this.saveNewRow();
            }
        },
        unDefaultCollisionsAndSave(){
            this.warningModal.collisions.forEach((col)=>{
                this.notDefaultRow(col);
            })
            this.saveNewRow();
        },
        saveNewRow(){
            console.log(`Create New Assignment`)
            var packed = {
                formID: this.modalValues.selectedFormID,
                userID: this.selectedUser,
                isDefault: this.modalValues.default,
                disabled: false,
            };
            // console.log(packed);
            var prom = butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/forms/assignments.json`, packed)
            prom.then((response)=>{
                this.modalValues.processing = false;
                this.$bvModal.hide('add-form');
                this.fetchAssignments();
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Failed To Update Assignment', 'EPC Check Failed, Not Authorized, Check with your administrator to receive permissions to update Assignment Records', 'danger')
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Failed To Update Assignment', 'EPC Check Failed, Not Authorized, Check with your administrator to receive permissions to update Assignment Records', 'danger')
                }else{
                    console.log(err);
                    butils.createToast(this, 'Failed To Update Assignment', 'An Error Occured, Check Console For Details', 'danger')
                }
                this.modalValues.processing = false;
                this.$bvModal.hide('add-form');
            })
            
        },
        defaultRow(row){
            if(!this.checkForCollisionsOnRow(row, this.unDefaultCollisionsAndAccept)){
                this.completeMarkDefault(row);
            }
        },
        checkForCollisionsOnRow(row, callback){
            console.log("Checking For Collisions")
            console.log(row);
            var rowForm = this.allAvailableForms.filter((avf)=>{ return avf.id == row.id || avf.id == row.form_id; })[0];
            row.form = rowForm;
            var userRows = this.allAssignmentRows.filter((asi)=>{ return asi.user_id == this.selectedUser && !asi.disabled })
            userRows.forEach((ur)=>{
                var match = this.allAvailableForms.filter((avf)=>{ return avf.id == ur.form_id; })[0];
                ur.form = match;
            })
            var collidingRows = userRows.filter((ur)=>{ return (ur.form.form_inspects == row.form.form_inspects && ur.is_default == true && ur.form_id != row.id) });
            if(collidingRows.length > 0){
                this.warningModal.processing = false;
                this.warningModal.row = row;
                this.warningModal.collisions = collidingRows;
                this.warningModal.confirmCallback = ()=>{
                    this.$bvModal.hide('confirm-collision-fixing')
                    callback();
                }
                this.$bvModal.show('confirm-collision-fixing');
                return true;
            }else{
                return false;
            }
        },
        unDefaultCollisionsAndAccept(){
            this.warningModal.collisions.forEach((col)=>{
                this.notDefaultRow(col);
            })
            this.completeMarkDefault(this.warningModal.row);
        },
        completeMarkDefault(row){
            console.log(`Set Assignment Default ${row.id}`)
            var pack = {
                id: row.id,
                disabled: row.disabled,
                isDefault: true
            };
            var prom = this.sendUpdateRequest(pack);
            prom.then(()=>{
                row.is_default = true;
            })
        },
        notDefaultRow(row){
            console.log(`Set Assignment Not Default ${row.id}`)
            var pack = {
                id: row.id,
                disabled: row.disabled,
                isDefault: false
            };
            var prom = this.sendUpdateRequest(pack);
            prom.then(()=>{
                row.is_default = false;
            })
        },
        disableRow(row){
            console.log(`Disable Assignment ${row.id}`)
            var pack = {
                id: row.id,
                disabled: true,
                isDefault: row.is_default
            };
            var prom = this.sendUpdateRequest(pack);
            prom.then(()=>{
                row.disabled = true;
            })
        },
        enableRow(row){
            console.log(`Enable Assignment ${row.id}`)
            var pack = {
                id: row.id,
                disabled: false,
                isDefault: row.is_default
            };
            var prom = this.sendUpdateRequest(pack);
            prom.then(()=>{
                row.disabled = false;
            })
        },
        sendUpdateRequest(pack){
            var prom = butils.instance.put(`${process.env.VUE_APP_API_BASE_URL}/forms/assignments.json`, pack)
            prom.then((response)=>{
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Failed To Update Assignment', 'EPC Check Failed, Not Authorized, Check with your administrator to receive permissions to update Assignment Records', 'danger')
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Failed To Update Assignment', 'EPC Check Failed, Not Authorized, Check with your administrator to receive permissions to update Assignment Records', 'danger')
                }else{
                    console.log(err);
                    butils.createToast(this, 'Failed To Update Assignment', 'An Error Occured, Check Console For Details', 'danger')
                }
            })
            return prom;
        },
        fetchFormList(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/forms/list.json`)
            .then((response)=>{
                var allForms = response.data.result;
                this.allAvailableForms = allForms;
                this.allLoadableFormsSelectList = [];
                this.formOptionsByType = {};
                allForms.forEach((form)=>{
                    if(form.is_custom_form){
                        this.allLoadableFormsSelectList.push({ value: form.id, text: `(${form.form_inspects}) ${form.name} (${form.notes})` })
                    }
                    if(_.has(this.formOptionsByType, form.form_inspects)){
                        this.formOptionsByType[form.form_inspects].push({ value: form.id, text: `${form.name} (${form.notes})` });
                    }else{
                        this.formOptionsByType[form.form_inspects] = [];
                        this.formOptionsByType[form.form_inspects].push({ value: form.id, text: `${form.name} (${form.notes})` });
                    }
                })
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Failed To Fetch Available Form List', 'Try logging in again', 'danger')
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Failed To Fetch Available Form List', 'Failed due to EPC Restrictions', 'danger')
                }else{
                    butils.createToast(this, 'Failed To Fetch Available Form List', 'Something went wrong, check the console for details.', 'danger')
                    console.log(err)
                }
            })
        },
        fetchAccountUsers(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/lists/account_users.json`)
            .then((response)=>{
                this.allUserRows = response.data.result.records;
                this.allUserRows.forEach((usr)=>{
                    this.userSelectList.push({ value: usr.id, text: `${usr.display_name} ${(usr.admin) ? '(Admin)': ''} ${(usr.is_self) ? '(You)': ''}`})
                })
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Failed To Fetch Account User List', 'Try logging in again', 'danger')
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Failed To Fetch Account User List', 'Failed due to EPC Restrictions', 'danger')
                }else{
                    butils.createToast(this, 'Failed To Fetch Account User List', 'Something went wrong, check the console for details.', 'danger')
                    console.log(err)
                }
            })
        },
        fetchAssignments(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/forms/assignments.json`)
            .then((response)=>{
                this.allAssignmentRows = response.data.result;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Failed To Fetch Form Assignment List', 'Try logging in again', 'danger')
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Failed To Fetch Form Assignment List', 'Failed due to EPC Restrictions', 'danger')
                }else{
                    butils.createToast(this, 'Failed To Fetch Form Assignment List', 'Something went wrong, check the console for details.', 'danger')
                    console.log(err)
                }
            })
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){
        this.fetchFormList();
        this.fetchAccountUsers();
        this.fetchAssignments();
    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>